import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import axios from '../../config/axios';
import { ButtonContainer } from './style';

export default class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: props.market ? false : true,
      disabled: props.market ? true : false,
      market: {
        id: props.market ? props.market.id : -1,
        region: props.market ? props.market.region : '',
        language: props.market ? props.market.language : '',
        username: props.market ? props.market.username : '',
        password: props.market ? props.market.password : '',
        token: props.market ? props.market.token : '',
        brand: props.market ? props.market.brand : '',
      },
    };
  }

  getDisabledState = () => {
    const { isNew, disabled } = this.state;
    return disabled && !isNew;
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  submitDisabled = () => {
    return Object.values(this.state.market).some(value => value.length === 0);
  };

  handleChange = (e, key) => {
    const { market } = this.state;
    e.persist();
    this.setState({
      market: {
        ...market,
        [key]: e.target.value,
      },
    });
  };

  updateMarket = async () => {
    const { market } = this.state;
    const { id, ...values } = market;
    try {
      await axios.put(`/market/${id}`, values);
      this.toggleDisabled();
      toast('Markt erfolgreich geupdated', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast('Es ist ein Fehler aufgetreten', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  postMarket = async () => {
    const { market } = this.state;
    const { id, ...values } = market;
    try {
      const { data } = await axios.post('/market', values);
      this.props.atMarketCreated(data);
      toast('Markt erfolgreich erstellt', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast('Es ist ein Fehler aufgetreten', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  removeMarket = async () => {
    const { market } = this.state;

    try {
      await axios.delete(`/market/${market.id}`);
      this.props.atMarketRemoved(market.id);
      toast('Markt erfolgreich gelöscht', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast('Es ist ein Fehler aufgetreten', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  showConfirmAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <h1>Bist du sicher?</h1>
            <p>Das Löschen kann nicht rückgängig gemacht werden</p>
            <button onClick={onClose} className="btn btn-primary">
              Nein
            </button>
            <button
              style={{ marginLeft: 10 }}
              className="btn btn-danger"
              onClick={() => {
                this.removeMarket();
                onClose();
              }}
            >
              Ja
            </button>
          </div>
        );
      },
    });
  };

  render() {
    const { market, isNew } = this.state;

    return (
      <>
        <form>
          <div className="form-row">
            <div className="col">
              <label>Region</label>
              <input
                type="text"
                className="form-control"
                value={market.region}
                onChange={e => this.handleChange(e, 'region')}
                disabled={this.getDisabledState()}
              />
            </div>
            <div className="col">
              <label>Language</label>
              <input
                type="text"
                className="form-control"
                value={market.language}
                onChange={e => this.handleChange(e, 'language')}
                disabled={this.getDisabledState()}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                value={market.username}
                onChange={e => this.handleChange(e, 'username')}
                disabled={this.getDisabledState()}
              />
            </div>
            <div className="col">
              <label>Passwort</label>
              <input
                type={this.getDisabledState() ? 'password' : 'text'}
                className="form-control"
                value={market.password}
                onChange={e => this.handleChange(e, 'password')}
                disabled={this.getDisabledState()}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              <label>Token</label>
              <input
                type={this.getDisabledState() ? 'password' : 'text'}
                className="form-control"
                value={market.token}
                onChange={e => this.handleChange(e, 'token')}
                disabled={this.getDisabledState()}
              />
            </div>
            <div className="col">
              <label>Brand</label>
              <input
                type="text"
                className="form-control"
                value={market.brand}
                onChange={e => this.handleChange(e, 'brand')}
                disabled={this.getDisabledState()}
              />
            </div>
          </div>
        </form>
        <ButtonContainer>
          {!isNew && (
            <button onClick={this.toggleDisabled} className="btn btn-primary">
              Edit
            </button>
          )}

          <button
            disabled={this.getDisabledState() || this.submitDisabled()}
            onClick={isNew ? this.postMarket : this.updateMarket}
            className="btn btn-primary"
          >
            Speichern
          </button>
          {!isNew && (
            <button onClick={this.showConfirmAlert} className="btn btn-danger">
              Löschen
            </button>
          )}
        </ButtonContainer>
        <hr />
      </>
    );
  }
}
