import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axios from '../config/axios';

const Wrapper = styled.div`
  margin: 20px 0 20px 0;
`;

const ButtonMargin = styled(Button)`
  margin-left: 10px;
`;

export default class ConfigurationUpload extends Component {
  constructor() {
    super();
    this.state = {
      fileToUpload: null,
      uploading: false,
    };
  }

  upload = async () => {
    const { fileToUpload } = this.state;
    const data = new FormData();

    data.append('file', fileToUpload);

    try {
      this.setState({
        uploading: true,
      });
      await axios.post('appconfiguration', data);
      toast('Konfiguration erfolgreich hochgeladen', {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      this.setUploadData(null);
      this.setState({
        uploading: false,
      });
    } catch (error) {
      toast('Fehler beim Hochladen der Konfiguration', {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  setUploadData = data => {
    this.setState({
      fileToUpload: data,
    });
  };

  handleUploadDataChange = e => {
    this.setUploadData(e.target.files[0]);
    // line resets the onchange handler so you can upload the same file twice
    e.target.value = '';
  };

  downloadConfiguration = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiPath = process.env.REACT_APP_API_PATH;
    window.open(`${apiUrl}${apiPath}/appconfiguration/`);
  };

  downloadConfigurationCSV = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiPath = process.env.REACT_APP_API_PATH;
    window.open(`${apiUrl}${apiPath}/appconfiguration/csv`);
  };

  render() {
    const { fileToUpload, uploading } = this.state;
    const formDisabled = !fileToUpload;
    return (
      <Wrapper>
        <h2>Konfigurationdatei(.csv) hochladen</h2>
        <label className="btn btn-primary">
          {fileToUpload ? fileToUpload.name : 'Datei auswählen'}
          <input
            type="file"
            name="appconfiguration"
            accept=".csv"
            onChange={this.handleUploadDataChange}
            hidden
          />
        </label>

        <br />
        <Button onClick={this.upload} disabled={formDisabled}>
          {uploading ? 'wird hochgeladen...' : 'Konfiguration hochladen'}
        </Button>
        <br />

        <br />
        <h2>Aktuelle Konfiguration herunterladen</h2>
        <Button onClick={this.downloadConfiguration}>
          Konfiguration herunterladen
        </Button>
        <ButtonMargin onClick={this.downloadConfigurationCSV}>
          CSV herunterladen
        </ButtonMargin>
      </Wrapper>
    );
  }
}
